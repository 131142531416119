import React, { useContext, useState, useEffect } from "react"
import { navigate } from "gatsby"
import { AuthContext } from "../context/auth"
import "../css/chat.css"
import firebase from "gatsby-plugin-firebase"
//import "firebase/firestore"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ChatMisChats from "../components/ChatMisChats"

import { useCollectionData } from "react-firebase-hooks/firestore"
import { ListItem, ListItemText, Divider } from "@material-ui/core"
import { injectIntl } from "gatsby-plugin-intl"

const MisChats = ({ intl }) => {
  //esto para ver si el usuario esta logueado
  const { user } = useContext(AuthContext)
  const [salaNombre, setSalaNombre] = useState("")

  const firestore = firebase.firestore()

  function ChatsListadoSalas(props) {
    const salasRef = firestore.collection(`stands-chats`)

    const query =
      localStorage.getItem("chat") !== "true"
        ? salasRef.where("cliente", "==", localStorage.getItem("uid"))
        : salasRef.where("correo_chat", "==", localStorage.getItem("email"))

    const [salas] = useCollectionData(query, { idField: "id" })

    return (
      <div>
        {salas && salas.map(sala => <ChatMessage key={sala.id} sala={sala} />)}
      </div>
    )
  }

  function ChatMessage(props) {
    const { nombre_cliente, nombre_stand, salanombre } = props.sala // en chat stand grabar mas campos en doc sala nombre de ambos etc
    //
    return (
      <>
        <div style={{ color: "#ffffff" }}>
          {localStorage.getItem("chat") !== "true" ? (
            <div>
              <Divider />
              <ListItem
                button
                key={props.key}
                onClick={() => setSalaNombre(salanombre)}
              >
                <ListItemText>{nombre_stand}</ListItemText>
              </ListItem>
            </div>
          ) : (
            <div>
              <Divider />
              <ListItem
                button
                key={props.key}
                onClick={() => setSalaNombre(salanombre)}
              >
                <ListItemText>{nombre_cliente}</ListItemText>
              </ListItem>
            </div>
          )}
        </div>
      </>
    )
  }
  useEffect(() => {
    if (!user) {
      navigate("/")
    }
  }, [])
  return (
    <>
      {user != null ? (
        <Layout>
          <SEO title="Mis Chats" />

          <div
            style={{
              position: "absolute",
              right: "0%",
              top: "0%",
              width: "75%",
              height: "100%",
              backgroundColor: "#064dae99",
              color: "#FFF",
            }}
          >
            {salaNombre !== "" ? <ChatMisChats salaNombre={salaNombre} /> : ""}
          </div>

          <div
            style={{
              position: "absolute",
              left: "0%",
              top: "0%",
              width: "25%",
              height: "100%",
              backgroundColor: "#064dae99",
              color: "#FFF",
            }}
          >
            <h3 style={{ textAlign: "center", width: "100%", marginTop: 10 }}>
              {localStorage.getItem("chat") !== "true" ? "Stands" : "Clientes"}
            </h3>

            <ChatsListadoSalas />
          </div>
        </Layout>
      ) : (
        <div></div>
      )}
    </>
  )
}

export default injectIntl(MisChats)

/**
 *
 * cada sala de chat en mis chats que sea un listitem button como en menu con ese efecto
 * correo chat lo paso a la creacion del documento y ese es el campo de filtrado que usa el admin del chat
 * tiene un campo adicional en su documento que se guarda en el HEADER agregar a localstorage, si da undefined
 * o lo mismo que en espago de auditorio lee por uid de cliente, si da algo lee por esa otra condicion
 */
/* 
const firestore = firebase.firestore()

function ChatListadoSalas(props) {
 // const { user } = useContext(AuthContext)

  const salasRef = firestore.collection(`stands-chats`)

  const query =
    localStorage.getItem("chat") !== "true"
      ? salasRef.where("cliente", "==", localStorage.getItem("uid"))
      : salasRef.where("correo_chat", "==", localStorage.getItem("email"))

  //  const query = salasRef.where("cliente", "==", localStorage.getItem("uid"))

  const [salas] = useCollectionData(query, { idField: "id" })

  return (
    <div className="ChatAuditorios">
      {salas && salas.map(sala => <ChatMessage key={sala.id} sala={sala} />)}
    </div>
  )
}

function ChatMessage(props) {
  const { cliente, nombre_cliente, nombre_stand, correo_chat } = props.sala // en chat stand grabar mas campos en doc sala nombre de ambos etc
  //
  return (
    <>
      <div style={{color: '#ffffff'}}>
        
          {localStorage.getItem("chat") !== "true" ? (
            <div>
              <Divider />
              <ListItem button key={props.key}>
                <ListItemText>{nombre_stand}</ListItemText>
              </ListItem>
            </div>
          ) : (
            <div>
              <Divider />
              <ListItem button key={props.key}>
                <ListItemText>{nombre_cliente}</ListItemText>
              </ListItem>
            </div>
          )}
      </div>
    </>
  )
} */
